import { PolicyContent } from './components/PolicyContent/PolicyContent';
import { PolicyHeader } from './components/PolicyHeader/PolicyHeader';
import { MOCK_DATA } from './mocks';
import styles from './PoliciesPage.module.scss';

import { JitText } from 'components/JitText/JitText';
import { PageTitles } from 'components/PageTitles/PageTitles';
import { CollapseSection } from 'pages/OverviewPage/components/CollapseSection/CollapseSection';

export const PoliciesPage = () => (
  <div className={styles.root}>
    <PageTitles title='pages.policies.title' />

    <div className={styles.content}>
      <JitText muted size='s' text='pages.policies.subtitle' />

      <div className={styles.policies}>
        {
        // TODO: remove in next PR
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        MOCK_DATA.map((policy: any) => (
          <CollapseSection
            key={policy.policySlug}
            header={<PolicyHeader description={policy.display.description} enabled={policy.dynamicData.enabledRulesCount} title={policy.display.displayName} />}
            withBackground
          >
            <PolicyContent />
          </CollapseSection>
        ))
        }
      </div>
    </div>
  </div>
);
