import { FC } from 'react';

import styles from './PolicyContent.module.scss';

import { JitText } from 'components/JitText/JitText';

export const PolicyContent: FC = () => (
  <div className={styles.root}>
    <JitText text='hello world' />
  </div>
);
