import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

export const MOCK_DATA = camelizeSnakeCaseKeys([
  {
    policy_slug: 'allow-ignore-findings',
    display: {
      displayName: 'Policy',
      description: 'This is a policy description',
    },
    dynamic_data: {
      enabled_rules_count: 5,
    },
    rules_templates: [
      {
        base_condition: '{user_identity_type} {user_identity_value} can ignore findings',
        filter_conditions: 'when {condition_entity} is with {condition_attribute} {condition_operator} {condition_value}',
        inputs: [
          {
            key: 'user_identity_type',
            label: 'User Identity Type',
            input_type: 'select',
            options: ['Role', 'Email'],
            default_value: 'Role',
            required: true,
          },
          {
            key: 'user_identity_value',
            label: 'User Identity Value',
            input_type: {
              Role: {
                type: 'select',
                multi: true,
                options: ['Admin', 'Member'],
              },
              Email: {
                type: 'text',
                multi: true,
                placeholder: 'Enter email address',
              },
            },
            depends_on: 'user_identity_type',
            required: true,
          },
          {
            key: 'condition_entity',
            label: 'Condition Entity',
            input_type: 'select',
            multi: false,
            options: ['Finding', 'Asset'],
            default_value: 'Finding',
            depends_on: 'user_identity_value',
            required: false,
          },
          {
            key: 'condition_attribute',
            label: 'Condition Attribute',
            input_type: 'select',
            multi: false,
            options: {
              Finding: ['severity', 'PriorityFactor', 'PriorityScore', 'Type', 'Name', 'CWEs'],
              Asset: ['PriorityFactor', 'PriorityScore', 'Type', 'Name'],
            },
            depends_on: 'condition_entity',
            required: true,
          },
          {
            key: 'condition_operator',
            label: 'Condition Operator',
            input_type: {
              severity: {
                type: 'select',
                options: ['equal'],
              },
              PriorityScore: {
                type: 'select',
                options: ['above', 'below', 'equal'],
                default_value: 'above',
              },
              PriorityFactor: {
                type: 'select',
                options: ['contains'],
              },
              Type: {
                type: 'select',
                options: ['equal'],
              },
              Name: {
                type: 'select',
                options: ['equal'],
              },
              CWEs: {
                type: 'select',
                options: ['equal'],
              },
            },
            depends_on: 'condition_attribute',
            required: true,
          },
          {
            key: 'condition_value',
            label: 'Condition Value',
            input_type: {
              Severity: {
                type: 'multi',
                options: ['High', 'Medium', 'Low'],
                multi: true,
              },
              PriorityScore: {
                type: 'number',
                placeholder: 'Enter threshold value',
                validation: {
                  min: 0,
                  max: 100,
                },
              },
              PriorityFactor: {
                type: 'select',
                multi: true,
                optionsCallback: {
                  Finding: 'FetchPriorityFactorsOptions',
                  Asset: 'FetchAssetPriorityFactorsOptions',
                },
                placeholder: 'Select priority factor(s)',
              },
              Type: {
                type: 'select',
                multi: true,
                api_endpoints: {
                  Finding: '/api/findings/types',
                  Asset: '/api/assets/types',
                },
                placeholder: 'Select type(s)',
              },
              Name: {
                type: 'select',
                multi: true,
                api_endpoints: {
                  Finding: '/api/findings/names',
                  Asset: '/api/assets/names',
                },
                placeholder: 'Select name(s)',
              },
              CWEs: {
                type: 'select',
                multi: true,
                api_endpoints: {
                  Finding: '/api/findings/cwes',
                },
                placeholder: 'Select CWE(s)',
              },
            },
            depends_on: 'condition_operator',
            required: true,
          },
        ],
      },
    ],
  },
]) as unknown[];
